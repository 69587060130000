<template>
  <v-container fluid class="wrapper px-0 py-0 mt-1">
    <v-row dense class="content-box">
      <v-col class="custom-col mt-4 pt-4">
        <v-row dense>
          <v-col sm="12" md="12">
            <v-row dense>
              <v-col md="12">
                <v-card class="survey-lists">
                  <v-data-table
                    :headers="headers"
                    :items="surveyData"
                    sort-by="ID"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Survey Lists</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Survey
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-form ref="form" lazy-validation>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.name"
                                        label="Survey Name"
                                        :rules="[
                                          () =>
                                            !!editedItem.name ||
                                            'This field is mandatory'
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.url"
                                        label="Url"
                                        :rules="[
                                          () =>
                                            !!editedItem.url ||
                                            'This field is mandatory'
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-textarea
                                        v-model="editedItem.final_text"
                                        label="Final Text"
                                      ></v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="close"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="headline"
                              >Are you sure you want to delete this
                              item?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeDelete"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItemConfirm"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.demographics="{ item }">
                      <a :href="`/admin/demographics/${item.url}`" target="_blank"
                        >View Demographics</a
                      >
                    </template>
                    <template v-slot:item.url="{ item }">
                      <a :href="baseUrl + '?survey=' + item.url" target="_blank"
                        >{{ baseUrl }}?survey={{ item.url }}</a
                      >
                    </template>
                    <template v-slot:item.reportLink="{ item }">
                      <a :href="baseUrl + item.reportLink" target="_blank"
                        >{{ baseUrl }}{{ item.reportLink }}</a
                      >
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-switch
                        v-model="item.status"
                        @change="updateItemStatus(item)"
                      ></v-switch>
                    </template>
                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Reset
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Nav />
    <v-overlay :opacity="0.7" :value="overlay" z-index="300">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script scoped="">
import Nav from "../partials/Nav";
import APIResource from "../../../services/api";
export default {
  components: { Nav },
  name: "SurveyList",
  data() {
    return {
      baseUrl: window.location.origin,
      overlay: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        {
          text: "Name",
          value: "name"
        },
        { text: "Url", value: "url", sortable: false },
        { text: "Report Link", value: "reportLink", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Demographics", value: "demographics", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      surveyData: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: "",
        url: "",
        status: 1,
        final_text: "",
        reportLink: ""
      },
      defaultItem: {
        id: 0,
        name: "",
        url: "",
        final_text: "",
        reportLink: ""
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Survey" : "Edit Survey";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const api = new APIResource();
      const surveys = await api.getSurveys();
      if (surveys.length > 0) {
        this.surveyData = [...surveys];
      }
      this.overlay = false;
    },
    editItem(item) {
      this.editedIndex = this.surveyData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async updateItemStatus(item) {
      this.overlay = true;
      const api = new APIResource();
      await api.updateSurvey(item);
      this.overlay = false;
    },
    deleteItem(item) {
      this.editedIndex = this.surveyData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.overlay = true;
      const api = new APIResource();
      await api.deleteSurvey(this.editedItem);
      this.surveyData.splice(this.editedIndex, 1);
      this.closeDelete();
      this.overlay = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        let ID = this.surveyData.length;
        ID += 1;
        this.editedItem.reportLink = `/reports/${this.editedItem.url}`;
        const api = new APIResource();
        if (this.editedIndex > -1) {
          const response = await api.updateSurvey(this.editedItem);
          if (response) {
            Object.assign(this.surveyData[this.editedIndex], this.editedItem);
          }
          this.overlay = false;
        } else {
          this.editedItem.id = ID;
          const response = await api.storeSurvey(this.editedItem);
          if (response) {
            this.surveyData.push(this.editedItem);
          }
          this.overlay = false;
        }
        this.close();
      }
    }
  }
};
</script>
<style scoped>
.content-box {
  position: relative;
  padding-left: 280px;
  padding-right: 20px;
}
</style>
