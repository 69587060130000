<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          dense
          class="clusters inner-questions"
          v-for="question in data"
          :key="question.id"
        >
          <v-col sm="12" md="12" class="px-0 py-0">
            <v-col sm="12" md="12" class="d-inline-flex px-0 py-0">
              <v-col sm="12" md="12" class="px-0 py-0"
                ><v-card-title class="normal-heading"
                  >{{ question.id }}. {{ question.question }}</v-card-title
                ></v-col
              >
            </v-col>
            <v-col sm="12" md="5" class="d-inline-flex normal-text">
              <v-col sm="12" md="3" class="d-inline-flex"
                >No. of Responses</v-col
              >
              <v-col sm="12" md="9" class="d-inline-flex"
                >Percent Responding</v-col
              >
            </v-col>
            <v-col sm="12" md="7" class="d-inline-flex normal-text">
              <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
              <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
              <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
              <v-col sm="12" md="2" class="d-inline-flex"
                >Neither Agree or Disagree</v-col
              >
              <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
              <v-col sm="12" md="2" class="d-inline-flex"
                >Strongly Disgree</v-col
              >
            </v-col>
          </v-col>
          <v-col sm="12" md="12" class="stats-wrapper">
            <v-col sm="12" md="5" class="d-inline-flex normal-text">
              <v-col sm="12" md="3" class="d-inline-flex">{{
                question.total
              }}</v-col>
              <v-col sm="12" md="9" class="d-inline-flex chart-container">
                <chart
                  :series="
                    question.chartData.length > 0 ? question.chartData : series
                  "
                ></chart>
              </v-col>
            </v-col>
            <v-col sm="12" md="7" class="d-inline-flex normal-text">
              <v-col sm="12" md="2" class="d-inline-flex">{{
                question.mean
              }}</v-col>
              <v-col sm="12" md="2" class="d-inline-flex"
                >{{ question.option1 }}%</v-col
              >
              <v-col sm="12" md="2" class="d-inline-flex"
                >{{ question.option2 }}%</v-col
              >
              <v-col sm="12" md="2" class="d-inline-flex"
                >{{ question.option3 }}%</v-col
              >
              <v-col sm="12" md="2" class="d-inline-flex"
                >{{ question.option4 }}%</v-col
              >
              <v-col sm="12" md="2" class="d-inline-flex"
                >{{ question.option5 }}%</v-col
              >
            </v-col>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script scoped>
import chart from "./chart";
export default {
  props: ["data"],
  components: {
    chart
  },
  data() {
    return {
      series: [
        {
          name: "Favorable",
          data: [11]
        },
        {
          name: "Unfavorable",
          data: [67]
        },
        {
          name: "Neutral",
          data: [22]
        }
      ]
    };
  }
};
</script>
<style>
.report .clusters .inner-questions .v-card__title {
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
  font-style: italic;
  color: var(--v-heading-base);
}
.report .clusters .normal-text {
  font-size: 0.9em;
}
.report .clusters .normal-text {
  padding: 0px;
}
.report .clusters .normal-text .col {
  text-align: center;
  justify-content: center;
}
.report .stats-wrapper {
  max-height: 85px;
  border-bottom: 1px solid #ddd;
}
.report .chart-container {
  padding: 0;
  position: relative;
  bottom: 1.5em;
}
.v-application--is-ltr button.v-expansion-panel-header {
  border: none;
  position: absolute;
  right: 20px;
  width: 40px;
  height: 40px;
  background: #ff773a;
  min-height: auto;
  text-align: center;
  border-radius: 30px;
  padding: 0;
  color: #fff;
  top: -25px;
}
.theme--light.v-expansion-panels
  button.v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  position: relative;
  right: 0em;
}
.v-expansion-panel-content {
  margin-top: 30px;
}
div.v-expansion-panel-header__icon {
  margin: 0 auto !important;
}
div.v-expansion-panel::before {
  box-shadow: none;
}
</style>
