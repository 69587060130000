<template>
  <v-container class="wrapper mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content pt-15">
          <v-row dense class="upper-block">
            <v-col sm="12" md="12" class="content-block">
              <h3 class="super-heading">DIBS</h3>
              <v-card-title>
                Diversity Inclusion Belonging Survey
              </v-card-title>
              <v-card-subtitle>Metrics that Matter</v-card-subtitle>
              <v-card-text>
                When it comes to diversity, equity, inclusion, and belonging in
                organizations, the old adage remains true: what doesn’t get
                measured, doesn’t get done and what gets measured matters.
                <br />
                <br />
                Now there’s an assessment tool designed especially for arts
                organizations, firms, agencies, non-profits, small businesses
                and corporations that captures data on member engagement and
                their sense of belonging, the inclusive characteristics of the
                organizational culture, and the degree to which diverse groups
                experience inclusion.
                <br />
                <br />
                <br />
                <br />
                DIBS captures the interplay of <span>identity</span>,
                <span>engagement</span>, and <span>inclusion</span> within the
                organization and defines <span>belonging</span> in its dynamic
                state. <span>That’s what DIBS measures!</span>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense class="mt-14">
            <v-col sm="12" md="4" class="content-block img-block">
              <img :src="require('@/assets/landing-image2.svg')" alt="" />
            </v-col>
            <v-col sm="12" md="8" class="content-block">
              <v-card-title>
                DIBS is...
              </v-card-title>
              <v-card-text class="list">
                <li>A 22-item survey with minimal response burden yielding rich
                  results.</li>
                <li>Theory based, reliable and valid measure</li>
                <li>Focused on the strategic direction for change and action
                  planning steps</li>
                <li>Designed to meet today’s need for solid accountability of
                  diversity, inclusion and belonging efforts</li>
                <li>Affordable and user-friendly</li>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense class="mt-10">
            <v-col sm="12" md="12" class="content-block">
              <v-card-title class="alt">About the Creator of DIBS</v-card-title>
              <v-card-text>
                Deborah L. Plummer, PhD, an international leader in the field of
                diversity and inclusion, is a psychologist, author and
                university professor with decades of experience in diversity
                metrics including designing these other widely used measures:
                <span>Diversity Engagement Survey</span>,
                <span>Racial Identity Status Self-Assessment</span>,
                <span>Antiracist Style Indicator</span>.
              </v-card-text>
            </v-col>
          </v-row>
          <v-row dense class="mt-10 mb-16">
            <v-col sm="12" md="12" class="content-block">
              <v-card-title class="alt"
                >What to learn more about DIBS?</v-card-title
              >
              <v-card-text>
                Schedule a half-hour presentation that provides an overview of
                the survey, the administration process and pricing guidelines:
                <span
                  ><a href="mailto:info@dlplummer.com"
                    >info@dlplummer.com</a
                  ></span
                >
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      overlay: false
    };
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0;
}
.upper-block .content-block {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.content-block h3.super-heading {
  font-weight: bolder;
  font-size: 2.5em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-secondary-base);
  padding: 16px;
  padding-bottom: 0;
}
.content-block .v-card__title {
  font-weight: bolder;
  font-size: 2em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-primary-base);
}
.content-block .v-card__title.alt {
  font-size: 1.8em;
  color: var(--v-paragraph-base);
}
.content-block .v-card__subtitle {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.8em;
  font-family: Roboto, sans-serif;
  color: var(--v-primary-base);
  letter-spacing: 1.15px;
  padding-bottom: 0px;
}
.content-block .v-card__text {
  padding-top: 20px;
  font-size: 1em;
  line-height: 1.5em;
  font-family: Roboto, sans-serif;
  color: var(--v-paragraph-base);
}
.content-block .v-card__text span {
  color: var(--v-primary-base);
  font-weight: bold;
}
.content-block img {
  width: 300px;
}
.content-block .v-card__text li {
  line-height: 1.8;
  list-style: none;
}
.content-block .v-card__text li::before{
  content: "\2022";
  color: var(--v-primary-base);
  font-weight: bold;
  display: inline-block;
  width: 0.8em;
}
.wrapper::before {
  content: url("~@/assets/landing-image1.png");
  position: absolute;
  z-index: 1;
  transform: scale(0.85);
  top: -6em;
  right: -4em;
}
.wrapper::after {
  content: url("~@/assets/landing-image3.svg");
  position: absolute;
  z-index: 1;
  left: -5em;
  transform: scale(0.5);
  bottom: -8em;
}
@media (min-width: 1920px) {
  .wrapper{
    padding-left: 65px;
  }
  .wrapper::before {
    transform: scale(0.7);
    top: -10em;
    right: -5em;
  }
  .wrapper::after {
    left: 0;
    transform: scale(1);
    bottom: 0;
  }
}
@media (min-width: 1028px) {
  .wrapper::before {
    transform: scale(0.7);
    top: -10em;
    right: -6em;
  }
  .wrapper::after {
    left: -6em;
    transform: scale(0.38);
    bottom: -10em;
  }
  .content-block .list{
    padding-left: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .row--dense > .col.content-block {
    padding: 0 30px;
  }
  .wrapper::before,
  .wrapper::after {
    content: "";
  }
  .upper-block .content-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .upper-block .content-block {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row--dense > .col.content-block {
    padding: 0 30px;
  }
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px !important;
  }
  .content-block.img-block {
    margin: 0 auto;
    text-align: center;
  }
  .content-block .v-card__text li {
    line-height: 1.8;
  }
}
</style>
