<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content mb-4">
          <v-row dense>
            <v-col sm="12" md="12" class="content-block text-center">
              <img width="90%" :src="require('@/assets/step-2.png')" alt="" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="survey-questions">
      <v-col md="6" sm="12">
        <v-card-text>
          If you wish, please provide any additional comments on your
          organization's diversity, equity, inclusion and belonging efforts.
        </v-card-text>
      </v-col>
      <v-col md="6" sm="12">
        <v-row dense class="global-options">
          <v-col md="12" align-self="start" class="text-center">
            <v-textarea rounded auto-grow outlined v-model="textContent">
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense class="mt-5 mb-10">
      <v-col>
        <v-card>
          <v-row dense>
            <v-col sm="12" md="12" class="footer-block text-right">
              <v-btn
                @click="validateSurvey"
                class="btn-start"
                x-large
                icon
                elevation="10"
                rounded
              >
                <v-icon right size="30">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SurveyDetail",
  data: function() {
    return {
      answers: [],
      question: {
        category: "survey",
        factor: "common purpose",
        id: 23,
        question:
          "If you wish, please provide any additional comments on your organization's diversity, equity, inclusion and belonging efforts.",
        survey_cluster: "comaraderie",
        type: "text"
      },
      textContent: ""
    };
  },
  methods: {
    validateSurvey() {
      let answers = [...this.answers];
      let question = { ...this.question };
      question.answer = this.textContent;
      let newAnswers = [...answers, question];
      localStorage.setItem("dibs_answers", JSON.stringify(newAnswers));
      localStorage.setItem("is_survey_detail_complete", "true");
      this.$router.push({ name: "Demographic" });
    }
  },
  async created() {
    let tempAns = JSON.parse(localStorage.getItem("dibs_answers"));
    this.answers = [...tempAns];
    localStorage.setItem("is_on_survey_detail", "true");
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0;
}
.wrapper .content {
  padding-top: 2.5em;
}
.content-block img {
  width: 25em;
}
.footer-block .btn-start {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.footer-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.survey-questions .v-card__title {
  color: var(--v-primary-base);
  font-size: 18px;
  font-weight: bold;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 4px;
  margin-right: 0;
}
.row--dense .image-block {
  text-align: right;
}

/* The container */
.custom-radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #b4adad;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: var(--v-buttonText-base);
  border: 2px solid var(--v-primary-base);
}

/* Create the indicator (the dot/circle - hidden when not checked) */

/* Show the indicator (dot/circle) when checked */

/* Style the indicator (dot/circle) */
.custom-radio .text-label {
  font-size: 16px;
  color: var(--v-paragraph-base);
}
.survey-questions .global-options {
  padding: 16px;
}
.global-options .text-label {
  font-size: 14px;
  color: var(--v-paragraph-base);
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .survey-questions {
    padding: 10px;
  }
  .survey-questions .global-options {
    padding: 10px;
  }
  .global-options .col {
    width: 20%;
    margin: 0 auto;
  }
  .custom-radio {
    padding-left: 20px;
    font-size: 18px;
  }
  .custom-radio .text-label {
    font-size: 12px;
    margin: 0;
    display: block !important;
  }

  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .content-block img {
    width: 20em;
  }
}
</style>
