<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content">
          <v-row dense>
            <v-col sm="12" md="12" class="content-block text-center">
              <img width="90%" :src="require('@/assets/step-3.png')" alt="" />
            </v-col>
            <v-col sm="12" md="12" class="para-block py-8">
              <v-card-text>
                To support your organization's efforts for building an inclusive
                environment, please complete the following demographic
                information. This information will be used to inform diversity
                strategic planning, inclusion programming, and diversity
                education. <br /><br />
                Answering the questions in this section is highly encouraged but
                completely optional. If you do not wish to provide this
                information please answer "I do not wish to answer" for each
                question. Please know that if you choose to provide us with this
                information, it will only be reported in aggregate. Thank you
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card class="questions mb-4 mt-4">
          <v-row dense align="center">
            <v-col
              sm="12"
              md="4"
              class="single--question-block"
              v-for="(item, index) in demoGraphicData"
              :key="index"
            >
              <v-card-title>{{ item.demographic }}:</v-card-title>
              <v-select
                :items="item.options"
                v-model="answers[item.slug]"
                :label="item.demographic"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mt-5 mb-10">
      <v-col>
        <v-card>
          <v-row dense>
            <v-col sm="12" md="12" class="footer-block text-right">
              <v-btn
                @click="validateDemographic"
                class="btn-start"
                x-large
                icon
                elevation="10"
                rounded
              >
                <v-icon right size="30">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import APIResource from "../services/api";

export default {
  name: "Demographic",
  data: function() {
    return {
      overlay: false,
      demoGraphicData: [],
      answers: {
        primary_position: "",
        time_with_organization: "",
        age_group: "",
        race_origin: "",
        gender: "",
        sexual_orientation: "",
        primary_language: "",
        belief_system: "",
        disability_status: ""
      }
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      let survey = JSON.parse(localStorage.getItem("dibs_current_survey"));
      const api = new APIResource();
      const demographics = await api.getAllDemograhics(survey.url);
      if (demographics.length > 0) {
        this.demoGraphicData = demographics.map(item => {
          item.options = JSON.parse(item.options);
          let options = item.options.map(i => {
            return i.value;
          });
          item.options = [...options];
          return item;
        });
        const newQuestions = this.demoGraphicData.map(item => {
          return item.slug;
        });
        const newAns = newQuestions.reduce(
          (acc, curr) => ((acc[curr] = ""), acc),
          {}
        );
        this.answers = { ...newAns };
      }
      this.overlay = false;
    },
    async validateDemographic() {
      this.overlay = true;
      let answers = { ...this.answers };

      let isEmptyAnswers = [];
      Object.keys(answers).forEach(val => {
        let value = answers[val];
        if (value === "") isEmptyAnswers.push(val);
      });

      if (isEmptyAnswers.length === 0) {
        let payload = {};
        let user = JSON.parse(localStorage.getItem("dibs_current_user"));
        let survey = JSON.parse(localStorage.getItem("dibs_current_survey"));
        let preAns = JSON.parse(localStorage.getItem("dibs_answers"));
        payload.user = user;
        payload.survey = survey;
        payload.answers = preAns;
        payload.demographics = answers;
        const api = new APIResource();
        await api.storeSurveyResponse(payload);
        localStorage.setItem("is_survey_complete", "true");
        await this.$router.push({ name: "Completion" });
      } else {
        alert("All the questions are mandatory!");
        this.overlay = false;
        return false;
      }
    }
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0 12px;
}
.wrapper .content {
  padding-top: 2.5em;
}
.content-block img {
  width: 25em;
}
.footer-block .btn-start {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.footer-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 4px;
  margin-right: 0;
}
.para-block .v-card__text {
  color: var(--v-paragraph-base);
  font-size: 16px;
}
.single--question-block .v-card__title {
  padding-left: 0px;
  padding-bottom: 10px;
  font-size: 16px;
  color: var(--v-paragraph-base);
  line-height: 1em;
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .content-block img {
    width: 20em;
  }
}
</style>
