import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home";
import Survey from "../views/Survey";
import Demographic from "../views/Demographic";
import Completion from "../views/Completion";
import SurveyDetail from "../views/SurveyDetail";
import Report from "../views/Report";
import Login from "../views/admin/Login";
import Dashboard from "../views/admin/Dashboard";
import SurveyList from "../views/admin/surveys/SurveyList";
import AdminDemographic from "../views/admin/demographics/Demographic";
import PageNotFound from "../views/PageNotFound";
import LandingPage from "../views/LandingPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/homepage",
    name: "LandingPage",
    component: LandingPage
  },
  {
    path: "/survey-questions",
    name: "Survey",
    component: Survey
  },
  {
    path: "/survey-description",
    name: "SurveyDetail",
    component: SurveyDetail
  },
  {
    path: "/demographic-questions",
    name: "Demographic",
    component: Demographic
  },
  {
    path: "/survey-completion",
    name: "Completion",
    component: Completion
  },
  {
    path: "/reports/:slug",
    name: "Report",
    component: Report
  },
  {
    path: "/admin/login",
    name: "Login",
    component: Login,
    meta: { guest: true }
  },
  {
    path: "/admin",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin/surveys",
    name: "SurveyList",
    component: SurveyList,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin/demographics/:survey",
    name: "AdminDemographic",
    component: AdminDemographic,
    meta: { requiresAuth: true }
  },
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/admin/login");
  } else {
    next();
  }
});

export default router;
