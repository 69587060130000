<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="75"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["series"],
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: "auto",
          stacked: true,
          stackType: "100%",
          redrawOnParentResize: true,
          toolbar: {
            show: false
          }
        },
        colors: [
          this.$vuetify.theme.themes.light.secondary,
          this.$vuetify.theme.themes.light.primary,
          this.$vuetify.theme.themes.light.alternate
        ],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          categories: ["2011 Q1"],
          position: "top",
          labels: {
            style: {
              fontSize: "9px"
            }
          }
        },
        yaxis: {
          show: false
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          position: "right",
          offsetX: 0,
          offsetY: 50
        },
        tooltip: {
          style: {
            fontSize: "9px"
          }
        }
      }
    };
  }
};
</script>
<style scoped>
#chart text {
  font: 9px;
}
</style>
