<template>
  <v-container fluid class="wrapper px-0 py-0 mt-1">
    <v-row dense class="content-box">
      <v-col class="custom-col mt-4 pt-4">
        <v-row dense>
          <v-col sm="12" md="12">
            <v-card class="dashboard">
              <v-card-title>Dashboard</v-card-title>
              <v-row dense>
                <v-col> </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Nav />
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script scoped="">
import Nav from "./partials/Nav";
export default {
  components: { Nav },
  name: "Dashboard",
  data() {
    return {
      overlay: false
    };
  }
};
</script>
<style scoped>
.content-box {
  position: relative;
  padding-left: 280px;
  padding-right: 20px;
}
</style>
