<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col class="custom-col">
        <v-card class="completion mt-16 pt-8">
          <v-row dense>
            <v-col sm="12" md="12" class="text-center">
              <v-card-title class="justify-center"
                >404 | Page not found</v-card-title
              >
              <v-card-text>
                Page your are look for doesn't exists. Click
                <a href="/">here</a> to start the survey.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
  data: function() {
    return {};
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0 12px;
}
.completion .v-card__text {
  color: var(--v-paragraph-base);
  font-size: 18px;
}
.completion .v-card__title {
  font-weight: 800;
  font-size: 3em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .custom-col {
    padding: 0 !important;
  }
  .completion {
    padding: 0 30px 40px 30px !important;
    margin-top: 0 !important;
  }
  .completion .v-card__text {
    color: var(--v-paragraph-base);
    font-size: 14px;
  }
  .completion .v-card__title {
    font-size: 1.5em;
  }
}
</style>
