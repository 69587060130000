var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"wrapper px-0 py-0 mt-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"content-box",attrs:{"dense":""}},[_c('v-col',{staticClass:"custom-col mt-4 pt-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"survey-lists"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.demoGraphicData,"sort-by":"ID"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Demographics")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Demographic ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Demographic Name","rules":[
                                        function () { return !!_vm.editedItem.demographic ||
                                          'This field is mandatory'; }
                                      ],"required":""},model:{value:(_vm.editedItem.demographic),callback:function ($$v) {_vm.$set(_vm.editedItem, "demographic", $$v)},expression:"editedItem.demographic"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Demographic Slug","rules":[
                                        function () { return !!_vm.editedItem.slug ||
                                          'This field is mandatory'; }
                                      ],"required":""},model:{value:(_vm.editedItem.slug),callback:function ($$v) {_vm.$set(_vm.editedItem, "slug", $$v)},expression:"editedItem.slug"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card-text',{style:({
                                        paddingLeft: 0,
                                        width: '50%',
                                        display: 'inline-flex'
                                      })},[_vm._v("Demographic Options")]),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.addOption}},[_vm._v("Add Option")]),_vm._l((_vm.editedItem.options),function(option,index){return _c('div',{key:index,style:({
                                        width: '90%',
                                        display: 'inline-flex'
                                      })},[_c('v-text-field',{attrs:{"label":"Option"},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}}),(index >= 1)?_c('svg',{staticClass:"ml-2 cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"},on:{"click":function($event){return _vm.removeOption(index)}}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"fill":"#f0450d","d":"M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"}})]):_vm._e()],1)})],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1),_c('Nav'),_c('v-overlay',{attrs:{"opacity":0.7,"value":_vm.overlay,"z-index":"300"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}},[_vm._v(" Loading... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }