import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FF7A3C",
        secondary: "#EF5023",
        alternate: "#00000029",
        heading: "#FF5100",
        paragraph: "#707070",
        buttonText: "#ffffff"
      }
    },
    options: { customProperties: true }
  }
});
