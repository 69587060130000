<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content">
          <v-row dense>
            <v-col sm="12" md="12" class="content-block text-center">
              <img width="90%" :src="require('@/assets/step-4.png')" alt="" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="custom-col">
        <v-card class="completion mt-4 pt-8">
          <v-row dense>
            <v-col sm="12" md="6">
              <v-card-title>Thank you for completing this survey.</v-card-title>
              <v-card-text v-if="surveyFinalText !== ''">
                {{ surveyFinalText }}
              </v-card-text>
            </v-col>
            <v-col sm="12" md="6">
              <img :src="require('@/assets/final.png')" alt="" class="final-img" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "Completion",
  data: function() {
    return {
      overlay: false,
      surveyFinalText: ""
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      let survey = JSON.parse(localStorage.getItem("dibs_current_survey"));
      this.surveyFinalText = survey.final_text !== "" ? survey.final_text : "";
      this.overlay = false;
    }
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0 12px;
}
.wrapper .content {
  padding-top: 2.5em;
}
.content-block img {
  width: 25em;
}
.footer-block .btn-start {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.footer-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 4px;
  margin-right: 0;
}
.completion .v-card__text {
  color: var(--v-paragraph-base);
  font-size: 16px;
}
.completion .v-card__title {
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
.completion img {
  width: 80%;
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .content-block img {
    width: 20em;
  }
  .custom-col {
    padding: 0 !important;
  }
  .completion {
    padding: 0 30px 40px 30px !important;
    margin-top: 0 !important;
  }
  .completion img.final-img {
    width: 100%;
    padding-top: 2em;
  }
}
</style>
