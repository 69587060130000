import APIResource from "../services/api";

const state = { user: null };

const getters = {
  isAuthenticated: state => !!state.user,
  StateUser: state => state.user
};
const actions = {
  async LogIn({ commit }, user) {
    const api = new APIResource();
    const response = await api.login(user);
    if (response) {
      commit("setUser", user.username);
    }
  },
  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  }
};
const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  logout(state, user) {
    state.user = user;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
