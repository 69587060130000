<template>
  <v-container fluid class="wrapper px-0 py-0 mt-1">
    <v-row dense class="content-box">
      <v-col class="custom-col mt-4 pt-4">
        <v-row dense>
          <v-col sm="12" md="12">
            <v-row dense>
              <v-col md="12">
                <v-card class="survey-lists">
                  <v-data-table
                    :headers="headers"
                    :items="demoGraphicData"
                    sort-by="ID"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Demographics</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Demographic
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-form ref="form" lazy-validation>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.demographic"
                                        label="Demographic Name"
                                        :rules="[
                                          () =>
                                            !!editedItem.demographic ||
                                            'This field is mandatory'
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem.slug"
                                        label="Demographic Slug"
                                        :rules="[
                                          () =>
                                            !!editedItem.slug ||
                                            'This field is mandatory'
                                        ]"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-card-text
                                        :style="{
                                          paddingLeft: 0,
                                          width: '50%',
                                          display: 'inline-flex'
                                        }"
                                        >Demographic Options</v-card-text
                                      >
                                      <v-btn small @click="addOption"
                                        >Add Option</v-btn
                                      >
                                      <div
                                        v-for="(option,
                                        index) in editedItem.options"
                                        :key="index"
                                        :style="{
                                          width: '90%',
                                          display: 'inline-flex'
                                        }"
                                      >
                                        <v-text-field
                                          v-model="option.value"
                                          label="Option"
                                        ></v-text-field>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                          class="ml-2 cursor-pointer"
                                          @click="removeOption(index)"
                                          v-if="index >= 1"
                                        >
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path
                                            fill="#f0450d"
                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                                          />
                                        </svg>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="close"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-form>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="headline"
                              >Are you sure you want to delete this
                              item?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeDelete"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItemConfirm"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Reset
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Nav />
    <v-overlay :opacity="0.7" :value="overlay" z-index="300">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script scoped="">
import Nav from "../partials/Nav";
import APIResource from "../../../services/api";
export default {
  components: { Nav },
  name: "AdminDemographic",
  data() {
    return {
      overlay: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        {
          text: "Survey",
          value: "survey"
        },
        { text: "Demographic", value: "demographic", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      surveyData: {},
      demoGraphicData: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        survey: "",
        demographic: "",
        slug: "",
        options: [{ value: "" }]
      },
      defaultItem: {
        id: 0,
        survey: "",
        demographic: "",
        slug: "",
        options: [{ value: "" }]
      }
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Demographic" : "Edit Demographic";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const url = window.location.pathname;
      const surveySlug = url.split("/").pop();
      const api = new APIResource();
      const survey = await api.getSurveyDetail(surveySlug);
      if (Object.keys(survey).length > 0) {
        this.surveyData = { ...survey };
        const demographics = await api.getAllDemograhics(surveySlug);
        if (demographics.length > 0) {
          this.demoGraphicData = demographics.map(item => {
            item.options = JSON.parse(item.options);
            return item;
          });
        }
      }
      this.overlay = false;
    },
    editItem(item) {
      this.editedIndex = this.demoGraphicData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.surveyData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.overlay = true;
      const api = new APIResource();
      await api.deleteDemograhic(this.editedItem);
      this.demoGraphicData.splice(this.editedIndex, 1);
      this.closeDelete();
      this.overlay = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        this.editedItem.survey = this.surveyData.url;
        let ID = new Date().valueOf();
        const api = new APIResource();
        if (this.editedIndex > -1) {
          const response = await api.updateDemograhic(this.editedItem);
          if (response) {
            Object.assign(
              this.demoGraphicData[this.editedIndex],
              this.editedItem
            );
          }
          this.overlay = false;
        } else {
          this.editedItem.id = ID;
          const response = await api.storeDemograhic(this.editedItem);
          if (response) {
            this.demoGraphicData.push(this.editedItem);
          }
          this.overlay = false;
        }
        this.editedItem.options = [...this.defaultItem.options];
        this.close();
      }
    },
    addOption() {
      this.editedItem.options.push({ value: "" });
      console.log("adding option", this.editedItem.options);
    },
    removeOption(index) {
      this.editedItem.options.splice(index, 1);
      console.log("removing option", this.editedItem.options);
    }
  }
};
</script>
<style scoped>
.content-box {
  position: relative;
  padding-left: 280px;
  padding-right: 20px;
}
</style>
