var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"wrapper px-0 py-0 mt-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"content-box",attrs:{"dense":""}},[_c('v-col',{staticClass:"custom-col mt-4 pt-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"survey-lists"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.surveyData,"sort-by":"ID"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Survey Lists")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Survey ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Survey Name","rules":[
                                        function () { return !!_vm.editedItem.name ||
                                          'This field is mandatory'; }
                                      ],"required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Url","rules":[
                                        function () { return !!_vm.editedItem.url ||
                                          'This field is mandatory'; }
                                      ],"required":""},model:{value:(_vm.editedItem.url),callback:function ($$v) {_vm.$set(_vm.editedItem, "url", $$v)},expression:"editedItem.url"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Final Text"},model:{value:(_vm.editedItem.final_text),callback:function ($$v) {_vm.$set(_vm.editedItem, "final_text", $$v)},expression:"editedItem.final_text"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.demographics",fn:function(ref){
                                      var item = ref.item;
return [_c('a',{attrs:{"href":("/admin/demographics/" + (item.url)),"target":"_blank"}},[_vm._v("View Demographics")])]}},{key:"item.url",fn:function(ref){
                                      var item = ref.item;
return [_c('a',{attrs:{"href":_vm.baseUrl + '?survey=' + item.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.baseUrl)+"?survey="+_vm._s(item.url))])]}},{key:"item.reportLink",fn:function(ref){
                                      var item = ref.item;
return [_c('a',{attrs:{"href":_vm.baseUrl + item.reportLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.baseUrl)+_vm._s(item.reportLink))])]}},{key:"item.status",fn:function(ref){
                                      var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateItemStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1),_c('Nav'),_c('v-overlay',{attrs:{"opacity":0.7,"value":_vm.overlay,"z-index":"300"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}},[_vm._v(" Loading... ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }