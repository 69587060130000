<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-card class="report mt-4 pt-10 mb-16" id="report">
      <v-row dense>
        <v-col sm="12" md="12">
          <v-card-title>Your DIBS Results!</v-card-title>
          <v-card-text>
            Responses are scored on a five-point Liker scale (5 = strongly agree
            agree and 1 = strongly disagree). Respondents are also given the
            opportunity to indicate that they were unable to evaluate. To
            minimize missing values, as this response option is more consistent
            with neither agreeing nor disagreeing, this option was set to 3
            (neither agree nor disagree). Results are compared across groups by
            the mean favorable response. The percentage responding as “Strongly
            agree” or “Agree” is computed as the <b>% Favorable</b>. The Overall
            Favorable is calculated for the three Engagement/Belonging Clusters
            and the eight Inclusion Factors. The average score across all 22
            items represents the Overall Favorable response for your
            organization.
          </v-card-text>
        </v-col>
      </v-row>
      <v-row dense class="clusters">
        <v-col sm="12" md="12">
          <v-col sm="12" md="6" class="d-inline-flex">
            <v-card-title class="normal-heading">Overall Score</v-card-title>
          </v-col>
          <v-col sm="12" md="6" class="d-inline-flex">
            <v-col sm="12" md="6">
              <v-select
                :items="demographics"
                item-text="demographic"
                item-value="code"
                label="Demographics"
                solo
                @change="changeOption($event)"
              ></v-select>
            </v-col>
            <v-col sm="12" md="6">
              <v-select
                :items="demographicSelectedOptions"
                label="Demographics Options"
                @change="filterReport($event)"
                solo
              ></v-select>
            </v-col>
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="4" class="d-inline-flex"
              >No. of Total Respondents</v-col
            >
            <v-col sm="12" md="8" class="d-inline-flex text-center"
              >Overall Favorable Scores</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="4" class="d-inline-flex">{{
              results.overall.total
            }}</v-col>
            <v-col sm="12" md="8" class="d-inline-flex chart-container">
              <chart
                v-if="results.overall.chartData"
                :series="
                  results.overall.chartData.length > 0
                    ? results.overall.chartData
                    : series
                "
              />
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.overall.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.overall.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.overall.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.overall.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.overall.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.overall.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions
          :data="
            results.clusters.comaraderie.questions
              .concat(results.clusters.vision_purpose.questions)
              .concat(results.clusters.appreciation.questions)
          "
        />
      </v-row>

      <v-row dense class="clusters mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="12"
              ><v-card-title class="normal-heading"
                >Comaraderie Cluster</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.clusters.comaraderie.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.clusters.comaraderie.chartData.length > 0
                    ? results.clusters.comaraderie.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.clusters.comaraderie.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.comaraderie.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.comaraderie.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.comaraderie.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.comaraderie.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.comaraderie.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.clusters.comaraderie.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Trust Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.trust.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.trust.chartData.length > 0
                    ? results.factors.trust.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.trust.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.trust.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.trust.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.trust.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.trust.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.trust.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.trust.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Sense of Belonging Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.sense_belonging.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.sense_belonging.chartData.length > 0
                    ? results.factors.sense_belonging.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.sense_belonging.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.sense_belonging.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.sense_belonging.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.sense_belonging.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.sense_belonging.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.sense_belonging.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.sense_belonging.questions" />
      </v-row>

      <v-row dense class="clusters mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8"
              ><v-card-title class="normal-heading"
                >Vision/Purpose Cluster</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.clusters.vision_purpose.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.clusters.vision_purpose.chartData.length > 0
                    ? results.clusters.vision_purpose.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.clusters.vision_purpose.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.vision_purpose.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.vision_purpose.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.vision_purpose.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.vision_purpose.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.vision_purpose.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.clusters.vision_purpose.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Common Purpose Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.common_purpose.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.common_purpose.chartData.length > 0
                    ? results.factors.common_purpose.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.common_purpose.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.common_purpose.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.common_purpose.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.common_purpose.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.common_purpose.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.common_purpose.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.common_purpose.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Access to Opportunity Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.access_opportunity.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.access_opportunity.chartData.length > 0
                    ? results.factors.access_opportunity.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.access_opportunity.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.access_opportunity.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.access_opportunity.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.access_opportunity.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.access_opportunity.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.access_opportunity.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.access_opportunity.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Equitable Reward and Recognition Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.equitable_reward_recognition.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.equitable_reward_recognition.chartData
                    .length > 0
                    ? results.factors.equitable_reward_recognition.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.equitable_reward_recognition.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{
                results.factors.equitable_reward_recognition.option1
              }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{
                results.factors.equitable_reward_recognition.option2
              }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{
                results.factors.equitable_reward_recognition.option3
              }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{
                results.factors.equitable_reward_recognition.option4
              }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{
                results.factors.equitable_reward_recognition.option5
              }}%</v-col
            >
          </v-col>
        </v-col>
        <questions
          :data="results.factors.equitable_reward_recognition.questions"
        />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Cultural Responsiveness Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.cultural_responsiveness.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.cultural_responsiveness.chartData.length > 0
                    ? results.factors.cultural_responsiveness.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.cultural_responsiveness.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.cultural_responsiveness.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.cultural_responsiveness.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.cultural_responsiveness.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.cultural_responsiveness.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.cultural_responsiveness.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.cultural_responsiveness.questions" />
      </v-row>

      <v-row dense class="clusters mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8"
              ><v-card-title class="normal-heading"
                >Appreciation Cluster</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.clusters.appreciation.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.clusters.appreciation.chartData.length > 0
                    ? results.clusters.appreciation.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.clusters.appreciation.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.appreciation.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.appreciation.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.appreciation.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.appreciation.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.clusters.appreciation.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.clusters.appreciation.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class=" py-0"
              ><v-card-title class="normal-heading py-0"
                >Appreciation of Individual Attributes Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.appreciation_differences.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.appreciation_differences.chartData.length > 0
                    ? results.factors.appreciation_differences.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.appreciation_differences.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.appreciation_differences.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.appreciation_differences.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.appreciation_differences.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.appreciation_differences.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.appreciation_differences.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.appreciation_differences.questions" />
      </v-row>
      <v-row dense class="clusters factors mt-10">
        <v-col sm="12" md="12">
          <v-col sm="12" md="12" class="d-inline-flex px-0">
            <v-col sm="12" md="8" class="py-0"
              ><v-card-title class="normal-heading py-0"
                >Respect Factor</v-card-title
              ></v-col
            >
          </v-col>
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">No. of Responses</v-col>
            <v-col sm="12" md="9" class="d-inline-flex"
              >Percent Responding</v-col
            >
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">Mean</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Agree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >Neither Agree or Disagree</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex">Disagree</v-col>
            <v-col sm="12" md="2" class="d-inline-flex">Strongly Disgree</v-col>
          </v-col>
        </v-col>
        <v-col sm="12" md="12" class="stats-wrapper">
          <v-col sm="12" md="5" class="d-inline-flex normal-text">
            <v-col sm="12" md="3" class="d-inline-flex">{{
              results.factors.respect.total
            }}</v-col>
            <v-col sm="12" md="9" class="d-inline-flex chart-container">
              <chart
                :series="
                  results.factors.respect.chartData.length > 0
                    ? results.factors.respect.chartData
                    : series
                "
              ></chart>
            </v-col>
          </v-col>
          <v-col sm="12" md="7" class="d-inline-flex normal-text">
            <v-col sm="12" md="2" class="d-inline-flex">{{
              results.factors.respect.mean
            }}</v-col>
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.respect.option1 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.respect.option2 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.respect.option3 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.respect.option4 }}%</v-col
            >
            <v-col sm="12" md="2" class="d-inline-flex"
              >{{ results.factors.respect.option5 }}%</v-col
            >
          </v-col>
        </v-col>
        <questions :data="results.factors.respect.questions" />
      </v-row>

      <v-row dense class="clusters mt-10" id="non-print1">
        <v-col sm="12" md="12" class="px-4 end-section">
          <h4>Raw Data</h4>
          <ul>
            <li>
              <a :href="exportLink" target="_blank">
                Export report
              </a>
            </li>
            <li>
              <a :href="exportLink2" target="_blank">
                Export report (Long Form Response)
              </a>
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row dense class="mt-5 mb-10" id="non-print2">
        <v-col>
          <v-card>
            <v-row dense>
              <v-col sm="12" md="12" class="footer-block text-right">
                <v-btn
                  class="btn-start"
                  x-large
                  elevation="10"
                  rounded
                  @click="print"
                >
                  PRINT
                  <v-icon right size="45">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import chart from "../components/chart";
import questions from "../components/questions";
import APIResource from "../services/api";
export default {
  components: {
    chart,
    questions
  },
  name: "Report",
  data: function() {
    return {
      exportLink:
        process.env.VUE_APP_API_URL +
        "/export-report?survey=" +
        this.$route.params.slug,
      exportLink2:
        process.env.VUE_APP_API_URL +
        "/export-report-text?survey=" +
        this.$route.params.slug,
      overlay: true,
      demographicSelectedOptions: [],
      demographicSelected: "",
      demographics: [
        {
          demographic: "Primary Position",
          code: "primary_position"
        }
      ],
      demographicOptions: {
        no_option: [],
        primary_position: [
          "Executive Administration",
          "Staff/Employee",
          "Board",
          "Patron/Student",
          "Community Partner/Volunteer",
          "I do not wish to answer"
        ],
        time_with_organization: [
          "Less than one year",
          "1 year to less than 5 years",
          "5 years to less than 10 year",
          "10 years to less than 20 year",
          "20 years or more",
          "I do not wish to answer"
        ],
        age_group: [
          "Traditional (1922-1944)",
          "Baby Boomers (1945-1964)",
          "Generation X (1965-1980)",
          "Millennial (1981-2000)",
          "Generation Z (2001-present)",
          "I do not wish to answer"
        ],
        race_origin: [
          "Hispanic/Latinx",
          "American Indian/Indigenous American/Alaska Native",
          "Asian",
          "Black/African American",
          "Native Hawaiian/Other Pacific Islander",
          "White",
          "Multiracial",
          "Not listed",
          "I do not wish to answer"
        ],
        gender: [
          "Male",
          "Female",
          "Trans Male/Trans Man",
          "Trans Female/Trans Woman",
          "Gender Non-Conforming/Gender Queer",
          "Non-binary",
          "Different Identity",
          "I do not wish to answer"
        ],
        sexual_orientation: [
          "Heterosexual",
          "Lesbian",
          "Gay",
          "Bisexual",
          "Queer",
          "Asexual",
          "Pansexual",
          "Different Orientation",
          "I do not wish to answer"
        ],
        primary_language: [
          "English",
          "Spanish",
          "German",
          "Polish",
          "Chinese (including Cantonese, Mandarin)",
          "French and French Creole",
          "Tagalog",
          "Vietnamese",
          "Hindustani",
          "Not Listed",
          "I do not wish to answer"
        ],
        belief_system: [
          "Christian",
          "Jewish",
          "Non-Believer",
          "Atheist",
          "Agnostic",
          "Humanistic",
          "Not Listed",
          "I do not wish to answer"
        ],
        disability_status: [
          "Yes, I have a disability",
          "No, I do not have a disability",
          "I do not wish to answer"
        ]
      },
      series: [
        {
          name: "Favorable",
          data: []
        },
        {
          name: "Unfavorable",
          data: []
        },
        {
          name: "Neutral",
          data: []
        }
      ],
      resetValue: {},
      results: {
        clusters: {
          appreciation: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          comaraderie: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          vision_purpose: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          }
        },
        factors: {
          trust: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          common_purpose: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          sense_belonging: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          access_opportunity: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          equitable_reward_recognition: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          cultural_responsiveness: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          appreciation_differences: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          },
          respect: {
            total: 0,
            noResponse: 0,
            option1: 0,
            option2: 0,
            option3: 0,
            option4: 0,
            option5: 0,
            mean: 0,
            chartData: [],
            questions: []
          }
        },
        overall: {
          total: 0,
          option1: 0,
          option2: 0,
          option3: 0,
          option4: 0,
          option5: 0,
          mean: 0,
          chartData: []
        }
      }
    };
  },
  methods: {
    print() {
      window.print();
    },
    changeOption(event) {
      this.demographicSelected = event;
      this.demographicSelectedOptions = [...this.demographicOptions[event]];
    },
    async filterReport(event) {
      let payload = {
        survey: this.$route.params.slug,
        demographic: this.demographicSelected,
        demographicOption: event
      };
      this.overlay = true;
      const api = new APIResource();
      const reportData = await api.getReportData(payload);
      if (Object.keys(reportData).length > 0) {
        this.results = { ...reportData };
      } else {
        this.results = { ...this.resetValue };
      }
      this.overlay = false;
    }
  },
  async created() {
    console.log();
    document.title = "Reports || DIBS";
    const api = new APIResource();
    const reportData = await api.getReportData({
      survey: this.$route.params.slug,
      demographic: "",
      demographicOption: ""
    });
    this.resetValue = { ...this.results };
    if (Object.keys(reportData).length > 0) {
      this.results = { ...reportData };
    }
    const demographics = await api.getAllDemograhics(this.$route.params.slug);
    if (demographics.length > 0) {
      let demoGraphicData = demographics.map(item => {
        item.options = JSON.parse(item.options);
        let options = item.options.map(i => {
          return i.value;
        });
        item.options = [...options];
        return item;
      });
      let newOptions = (demoGraphicData || []).reduce((add, cur) => {
        add[cur.slug] = cur.options;
        return add;
      }, {});
      newOptions = Object.assign({}, { ...newOptions }, { no_option: [] });
      this.demographicOptions = { ...newOptions };
      const newDemographics = demographics.map(item => {
        return { demographic: item.demographic, code: item.slug };
      });
      this.demographics = [...newDemographics];
    }

    this.overlay = false;
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0 12px;
}
.wrapper .content {
  padding-top: 2.5em;
}
.content-block img {
  width: 25em;
}
.footer-block .btn-start {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.footer-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 4px;
  margin-right: 0;
}
.report .v-card__text {
  color: var(--v-paragraph-base);
  font-size: 16px;
}
.report .v-card__title {
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
.report .factors .v-card__title {
  font-size: 1.5em;
  font-style: italic;
}
.report .normal-heading {
  font-weight: normal;
}
.report .clusters .inner-questions .v-card__title {
  font-weight: normal;
  font-size: 1em;
  line-height: 1em;
  font-style: italic;
}
.report .clusters .normal-text {
  font-size: 0.9em;
}
.report .clusters .normal-text {
  padding: 0px;
}
.report .clusters .normal-text .col {
  text-align: center;
  justify-content: center;
}
.report .stats-wrapper {
  max-height: 85px;
  border-bottom: 1px solid #ddd;
}
.report .chart-container {
  padding: 0;
  position: relative;
  bottom: 1.5em;
}
.v-application--is-ltr button.v-expansion-panel-header {
  border: none;
  position: absolute;
  right: 20px;
  width: 40px;
  height: 40px;
  background: #ff773a;
  min-height: auto;
  text-align: center;
  border-radius: 30px;
  padding: 0;
  color: #fff;
  top: -25px;
}
.theme--light.v-expansion-panels
  button.v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  position: relative;
  right: 0.3em;
}
.v-expansion-panel-content {
  margin-top: 30px;
}
div.v-expansion-panel-header__icon {
  margin: 0 auto !important;
}
div.v-expansion-panel::before {
  box-shadow: none;
}
.report .end-section h4 {
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.2em;
  text-decoration: underline;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
.report .end-section ul {
  padding-left: 1em;
  margin-top: 1em;
}
.report .end-section ul > li {
  font-size: 1em;
  line-height: 1.5em;
  font-family: Roboto, sans-serif;
}
.report .footer-block .btn-start {
  font-size: 1.8em;
  line-height: 1.5em;
  font-family: Roboto, sans-serif;
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .content-block img {
    width: 20em;
  }
  .custom-col {
    padding: 0 !important;
  }
  .completion {
    padding: 0 30px 40px 30px !important;
    margin-top: 0 !important;
  }
}
@media print {
  @page {
    margin: 0 5mm; /* this affects the margin in the printer settings */
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  header {
    display: none;
  }
  #non-print1,
  #non-print2 {
    display: none;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  #report .apexcharts-canvas {
    max-width: 25%;
  }
}
</style>
