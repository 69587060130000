<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense class="login">
      <v-col class="custom-col mt-4 pt-8">
        <v-row dense>
          <v-col sm="12" md="12">
            <v-card width="550" class="mx-auto login-box">
              <v-card-title class="justify-center">Admin Login</v-card-title>
              <v-card-subtitle
                class="text-center mt-4 error--text"
                v-if="showError"
                >Username or Password is incorrect</v-card-subtitle
              >
              <v-form ref="form" lazy-validation>
                <v-card-text>
                  <v-text-field
                    label="Username"
                    filled
                    color="black"
                    prepend-icon="mdi-account-circle"
                    ref="email"
                    v-model="form.username"
                    :rules="[
                      () => !!form.username || 'This field is mandatory'
                    ]"
                    placeholder="Username"
                    required
                  />
                  <v-text-field
                    label="Password"
                    filled
                    color="black"
                    :type="myPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    :append-icon="myPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="myPassword = !myPassword"
                    ref="password"
                    v-model="form.password"
                    :rules="[
                      () => !!form.password || 'This field is mandatory'
                    ]"
                    placeholder="Password"
                    required
                  />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn class="btn-login" rounded @click="submit"
                    >Login Here</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Logging in...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      myPassword: "",
      showError: false,
      overlay: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        const User = { ...this.form };
        try {
          await this.LogIn(User);
          await this.$router.push("/admin");
          this.overlay = false;
          this.showError = false;
        } catch (error) {
          this.overlay = false;
          this.showError = true;
        }
      }
    }
  }
};
</script>
<style scoped>
.login-box .v-card__title {
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
.login-box .btn-login {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.login-box .btn-login:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
</style>
