<template>
  <v-card class="admin-nav">
    <v-navigation-drawer permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Admin
          </v-list-item-title>
          <v-list-item-subtitle>
            Navigations
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="#" @click.prevent="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script scoped="">
import { mapActions } from "vuex";
export default {
  name: "Navigation",
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "/admin" },
        { title: "Surveys", icon: "mdi-clipboard", url: "/admin/surveys" },
      ],
      right: null
    };
  },
  methods: {
    ...mapActions(["LogOut"]),
    async logout() {
      await this.LogOut();
      await this.$router.push("/admin");
    }
  }
};
</script>
<style scoped>
.admin-nav {
  min-height: 400px;
  position: absolute;
  top: 2em;
  width: 256px;
}
</style>
