<template>
  <v-container class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content mb-4">
          <v-row dense>
            <v-col sm="12" md="12" class="content-block text-center">
              <img width="90%" :src="require('@/assets/step-2.png')" alt="" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mt-4 survey-questions">
      <v-col md="6" sm="12">
        <v-card-title>
          Rate your agreement to the following statements:
        </v-card-title>
      </v-col>
      <v-col md="6" class="d-none d-md-flex">
        <v-row dense class="global-options">
          <v-col align-self="start" class="text-center text-label"
            >Strongly Agree</v-col
          >
          <v-col align-self="start" class="text-center text-label">Agree</v-col>
          <v-col align-self="start" class="text-center text-label"
            >Neither Agree <br />
            or Disagree</v-col
          >
          <v-col align-self="start" class="text-center text-label"
            >Disagree</v-col
          >
          <v-col align-self="start" class="text-center text-label"
            >Strongly Disagree</v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row
      dense
      class="survey-questions"
      v-for="(question, index) in questions"
      :key="index"
    >
      <v-col md="6" sm="12">
        <v-card-text> {{ index + 1 }}. {{ question.question }} </v-card-text>
      </v-col>
      <v-col md="6" sm="12">
        <v-row dense class="global-options">
          <v-col
            align-self="start"
            class="text-center"
            v-if="question.type === 'radio'"
          >
            <label class="custom-radio">
              <input
                type="radio"
                value="5"
                @change="addAnswer(question.id, 5)"
                v-bind:name="'questions' + index"
              />
              <span class="checkmark"></span>
              <p class="text-label d-none d-sm-flex d-md-none">
                Strongly Agree
              </p>
            </label>
          </v-col>
          <v-col
            align-self="start"
            class="text-center"
            v-if="question.type === 'radio'"
          >
            <label class="custom-radio">
              <input
                type="radio"
                value="4"
                @change="addAnswer(question.id, 4)"
                v-bind:name="'questions' + index"
              />
              <span class="checkmark"></span>
              <p class="text-label d-none d-sm-flex d-md-none">Agree</p>
            </label>
          </v-col>
          <v-col
            align-self="start"
            class="text-center"
            v-if="question.type === 'radio'"
          >
            <label class="custom-radio">
              <input
                type="radio"
                value="3"
                @change="addAnswer(question.id, 3)"
                v-bind:name="'questions' + index"
              />
              <span class="checkmark"></span>
              <p class="text-label d-none d-sm-flex d-md-none">
                Neither Agree <br />
                or Disagree
              </p>
            </label>
          </v-col>
          <v-col
            align-self="start"
            class="text-center"
            v-if="question.type === 'radio'"
          >
            <label class="custom-radio">
              <input
                type="radio"
                value="2"
                @change="addAnswer(question.id, 2)"
                v-bind:name="'questions' + index"
              />
              <span class="checkmark"></span>
              <p class="text-label d-none d-sm-flex d-md-none">Disagree</p>
            </label>
          </v-col>
          <v-col
            align-self="start"
            class="text-center"
            v-if="question.type === 'radio'"
          >
            <label class="custom-radio">
              <input
                type="radio"
                value="1"
                @change="addAnswer(question.id, 1)"
                v-bind:name="'questions' + index"
              />
              <span class="checkmark"></span>
              <p class="text-label d-none d-sm-flex d-md-none">
                Strongly Disagree
              </p>
            </label>
          </v-col>
          <v-col
            md="12"
            align-self="start"
            class="text-center"
            v-if="question.type === 'text'"
          >
            <v-textarea
              rounded
              auto-grow
              outlined
              class="mt-4"
              v-model="textContent"
              v-bind:name="'questions' + index"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense class="mt-5 mb-10">
      <v-col>
        <v-card>
          <v-row dense>
            <v-col sm="12" md="12" class="footer-block text-right">
              <v-btn
                @click="validateSurvey"
                class="btn-start"
                x-large
                icon
                elevation="10"
                rounded
              >
                <v-icon right size="30">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import APIResource from "../services/api";

export default {
  name: "Survey",
  data: function() {
    return {
      answers: [],
      overlay: false,
      textContent: "",
      questions: [
        {
          text:
            "I trust my institution to be fair to all employees and students.",
          type: "radio"
        },
        {
          text:
            "The leadership of my institution is committed to treating people respectfully.",
          type: "radio"
        },
        {
          text: "I am valued as an individual by my institution.",
          type: "radio"
        },
        {
          text:
            "I feel that my work or studies contribute to the mission of the institution.",
          type: "radio"
        },
        {
          text: "Please describe ...",
          type: "text"
        }
      ]
    };
  },
  methods: {
    addAnswer(id, value) {
      let answers = [...this.answers];
      answers[id] = value;
      this.answers = [...answers];
    },
    validateSurvey() {
      this.overlay = true;
      let answers = [...this.answers];

      let isEmptyAnswers = answers.filter(
        (item, index) => index !== 0 && item === 0
      );
      if (isEmptyAnswers.length === 0) {
        let tempQuestion = [...this.questions];
        tempQuestion = tempQuestion.map(item => {
          if (item.type === "radio") item.answer = answers[item.id];
          if (item.type === "text") item.answer = this.textContent;
          return item;
        });
        localStorage.setItem("dibs_answers", JSON.stringify(tempQuestion));
        this.$router.push({ name: "SurveyDetail" });
      } else {
        alert("All the questions are mandatory!");
        this.overlay = false;
        return false;
      }
    }
  },
  async created() {
    this.overlay = true;
    const api = new APIResource();
    this.questions = await api.getQuestionsByCategory("survey");

    const tempQuestion = [...this.questions];
    let tempAns = [];
    for (let tempQuestionElement of tempQuestion) {
      if (tempQuestionElement.type === "radio")
        tempAns[tempQuestionElement.id] = 0;
    }
    this.answers = [...tempAns];
    localStorage.setItem("is_on_survey", "true");
    this.overlay = false;
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0;
}
.wrapper .content {
  padding-top: 2.5em;
}
.content-block img {
  width: 25em;
}
.footer-block .btn-start {
  color: var(--v-buttonText-base);
  background: var(--v-primary-base);
}
.footer-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.survey-questions .v-card__title {
  color: var(--v-primary-base);
  font-size: 18px;
  font-weight: bold;
}
.v-application--is-ltr .v-btn__content .v-icon--right {
  margin-left: 4px;
  margin-right: 0;
}
.row--dense .image-block {
  text-align: right;
}

/* The container */
.custom-radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #b4adad;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: var(--v-buttonText-base);
  border: 2px solid var(--v-primary-base);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 3px;
  left: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--v-primary-base);
}
.custom-radio .text-label {
  font-size: 16px;
  color: var(--v-paragraph-base);
}
.survey-questions .global-options {
  padding: 16px;
}
.global-options .text-label {
  font-size: 14px;
  color: var(--v-paragraph-base);
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px;
  }
  .row--dense > .col.content-block {
    padding: 20px;
  }
  .survey-questions {
    padding: 10px;
  }
  .survey-questions .global-options {
    padding: 10px;
  }
  .global-options .col {
    width: 20%;
    margin: 0 auto;
  }
  .custom-radio {
    padding-left: 20px;
    font-size: 18px;
  }
  .custom-radio .text-label {
    font-size: 12px;
    margin: 0;
    display: block !important;
  }
  .row--dense > .col.footer-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .content-block img {
    width: 20em;
  }
}
</style>
