<template>
  <v-container fluid class="wrapper px-0 py-0 mt-1">
    <v-row dense>
      <v-col>
        <v-card class="content pt-15">
          <v-row dense>
            <v-col sm="12" md="6" class="content-block">
              <v-card-title>
                Getting Started!
              </v-card-title>
              <v-card-text>
                The Diversity Inclusion Belonging Survey (DIBS) is designed to
                capture data on your organization’s member engagement and sense
                of belonging, the inclusiveness characteristics of the
                organization culture and the degree to which diverse groups
                experience inclusion.
                <br />
                <br />
                It should take approximately 10 minutes to complete. Your
                responses will only be reported in aggregate form and the report
                provided only to authorized parties. Results from the survey
                will be used to support forwarding your organization’s
                diversity, equity, inclusion and belonging (DEIB) goals. Thank
                you for participating.
              </v-card-text>
              <v-btn
                to="/survey-questions"
                class="btn-start"
                x-large
                elevation="10"
                :color="this.$vuetify.theme.themes.light.primary"
                rounded
              >
                Start
                <v-icon right size="30">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
            <v-col sm="12" md="6" class="image-block">
              <img :src="require('@/assets/home-banner.png')" alt="" />
            </v-col>
          </v-row>
          <v-dialog v-model="invalidDialog" persistent max-width="390">
            <v-card>
              <v-card-title class="headline error--text">
                Wait here !
              </v-card-title>
              <v-card-text
                >You cannot start the survey without proper survey link. Please
                contact your administrator for survey link</v-card-text
              >
            </v-card>
          </v-dialog>
          <v-dialog v-model="errorDialog" persistent max-width="390">
            <v-card>
              <v-card-title class="headline error--text">
                Wait here !
              </v-card-title>
              <v-card-text v-text="errorText"></v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :opacity="0.7" :value="overlay">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import APIResource from "../services/api";
import { uuid } from "vue-uuid";
export default {
  name: "Home",
  data() {
    return {
      overlay: false,
      invalidDialog: false,
      errorDialog: false,
      errorText: ""
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const surveyUrl = urlParams.get("survey");
    if (!surveyUrl) {
      await this.$router.push({ name: "LandingPage" });
    } else {
      this.overlay = true;
      document.title = "Diversity Inclusion Belonging Survey";
      if (localStorage.getItem("dibs_current_survey") !== null) {
        const oldSurvey = JSON.parse(
          localStorage.getItem("dibs_current_survey")
        );
        if (oldSurvey.url !== surveyUrl) {
          localStorage.removeItem("dibs_machineId");
          localStorage.removeItem("dibs_current_user");
          localStorage.removeItem("dibs_current_survey");
          localStorage.removeItem("is_on_survey");
          localStorage.removeItem("is_on_survey_detail");
          localStorage.removeItem("is_survey_detail_complete");
          localStorage.removeItem("dibs_answers");
          localStorage.removeItem("is_survey_complete");
        }
      }

      if (localStorage.getItem("dibs_machineId") === null) {
        let newId = uuid.v4();
        const api = new APIResource();
        const user = await api.storeUser({ machine_id: newId });
        const survey = await api.getSurveyDetail(surveyUrl);
        if (Object.keys(survey).length > 0) {
          localStorage.setItem("dibs_machineId", newId);
          localStorage.setItem("dibs_current_user", JSON.stringify(user));
          localStorage.setItem("dibs_current_survey", JSON.stringify(survey));
        } else {
          this.errorText = "Invalid Survey Link!!.";
          this.errorDialog = true;
        }
        this.overlay = false;
      } else {
        if (localStorage.getItem("is_on_survey") !== null) {
          this.$router.push({ name: "Survey" });
        }

        if (localStorage.getItem("is_on_survey_detail") !== null) {
          this.$router.push({ name: "SurveyDetail" });
        }

        if (
          localStorage.getItem("dibs_answers") !== null &&
          localStorage.getItem("is_survey_detail_complete") !== null &&
          localStorage.getItem("is_survey_complete") === null
        ) {
          this.$router.push({ name: "Demographic" });
        }

        if (
          localStorage.getItem("dibs_answers") !== null &&
          localStorage.getItem("is_survey_complete") !== null
        ) {
          this.$router.push({ name: "Completion" });
        }
        this.overlay = false;
      }
    }
  }
};
</script>

<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.row--dense > .col {
  padding: 0;
}
.row--dense > .col.content-block {
  padding-left: 15em;
}
.content-block .v-card__title {
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.2em;
  font-family: Roboto, sans-serif;
  color: var(--v-heading-base);
}
.content-block .v-card__text {
  padding-top: 20px;
  font-size: 1em;
  line-height: 1.5em;
  font-family: Roboto, sans-serif;
  color: var(--v-paragraph-base);
}
.content-block .btn-start {
  color: var(--v-buttonText-base);
}
.content-block .btn-start:hover {
  background-color: var(--v-secondary-base) !important;
  border-color: var(--v-secondary-base) !important;
}
.image-block img {
  height: 30.58em;
  width: 80%;
}
.row--dense .image-block {
  text-align: right;
}
@media only screen and (max-width: 1024px) {
  .row--dense > .col.content-block {
    padding: 0 50px 40px 50px;
  }
}
@media (max-width: 768px) {
  .col {
    flex-basis: auto;
  }
  .wrapper .content {
    padding-top: 30px !important;
  }
  .row--dense > .col.content-block {
    padding: 0 50px 40px 50px;
  }
  .row--dense > .col.content-block .btn-start {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .image-block img {
    height: auto;
    width: 100%;
  }
}
</style>
