import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHtmlToPaper from "vue-html-to-paper";

Vue.config.productionTip = false;
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://fonts.gstatic.com",
    "https://fonts.googleapis.com/css2?family=Roboto&display=swap",
    "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css",
    "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
    require("@/assets/css/print.css")
  ]
};
Vue.use(VueHtmlToPaper, options);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
