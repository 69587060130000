import axios from "axios";

export default class APIResource {
  constructor() {
    this.apiUrl = process.env.VUE_APP_API_URL;
  }

  async storeUser(payload) {
    try {
      const response = await axios.post(`${this.apiUrl}/api/user`, payload);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getQuestionsByCategory(category) {
    try {
      const response = await axios.get(`${this.apiUrl}/api/questionnaire`, {
        params: {
          category: category
        }
      });
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getSurveyDetail(slug) {
    try {
      const response = await axios.get(`${this.apiUrl}/api/survey`, {
        params: {
          url: slug
        }
      });
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async storeSurveyResponse(payload) {
    try {
      const response = await axios.post(
        `${this.apiUrl}/api/questionnaire/store`,
        payload
      );
      // console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getReportData(payload = {}) {
    try {
      const response = await axios.get(`${this.apiUrl}/api/result`, {
        params: { ...payload }
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getSurveys() {
    try {
      const response = await axios.get(`${this.apiUrl}/api/surveys`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async storeSurvey(payload) {
    try {
      const response = await axios.post(
        `${this.apiUrl}/api/survey/create`,
        payload
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async updateSurvey(payload) {
    try {
      const response = await axios.put(
        `${this.apiUrl}/api/survey/update`,
        payload
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteSurvey(payload) {
    try {
      const response = await axios.delete(`${this.apiUrl}/api/survey/delete`, {
        data: { ...payload }
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getAllDemograhics(survey) {
    try {
      const response = await axios.get(`${this.apiUrl}/api/demographics`, {
        params: {
          survey: survey
        }
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getSingleDemograhic(survey,demographic) {
    try {
      const response = await axios.get(`${this.apiUrl}/api/demographic`, {
        params: {
          survey: survey,
          demographic: demographic
        }
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async storeDemograhic(payload) {
    try {
      const response = await axios.post(
        `${this.apiUrl}/api/demographic/create`,
        payload
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async updateDemograhic(payload) {
    try {
      const response = await axios.put(
        `${this.apiUrl}/api/demographic/update`,
        payload
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteDemograhic(payload) {
    try {
      const response = await axios.delete(
        `${this.apiUrl}/api/demographic/delete`,
        {
          data: { ...payload }
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async login(user) {
    try {
      const response = await axios.post(`${this.apiUrl}/api/login`, user);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
