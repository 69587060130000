<template>
  <v-app>
    <v-app-bar
      elevation="0"
      app
      center
      height="160"
      color="#ffffff"
      class="header px-0 py-0"
    >
      <v-container fluid class="custom-container">
        <v-row class="row-header">
          <v-col md="12" sm="12">
            <a href="https://www.dlplummer.com/" target="_blank" class="logo-link">
              <img
                class="logo"
                :src="require('@/assets/logo.png')"
                alt="logo"
              />
            </a>
            <v-card-title>
              Diversity Inclusion Belonging Survey (DIBS)
            </v-card-title>
          </v-col>
        </v-row>
        <img class="strip-bar" :src="require('@/assets/bar.png')" alt="" />
        <v-spacer></v-spacer>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App"
};
</script>
<style scoped>
.row-header {
  width: 100%;
  margin: 0;
}
.header .theme--light.v-image {
  float: left;
}
.header .v-card__title {
  color: var(--v-heading-base);
  font-weight: 800;
  font-size: 3em;
  line-height: 1.3em;
  font-family: Roboto, sans-serif;
  padding-left: 1.2em;
  display: inline-flex;
  flex-direction: row;
}
.v-main .top-spacer {
  border-top: 15px solid var(--v-secondary-base) !important;
}
.header >>> .v-toolbar__content {
  padding: 0;
}
.header .custom-container{
  padding: 0;
}
.strip-bar{
  width: 100%;
  height: 35px;
}
.logo-link{
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  padding-left: 7.5%;
}
@media only screen and (max-width : 1244px) {
  .header .v-card__title{
    font-size: 2.5em;
  }
}
@media only screen and (max-width : 1024px) {
  .header .v-card__title{
    font-size: 2em;
  }
}
@media only screen and (max-width : 992px) {
  .header .v-card__title{
    font-size: 2em;
  }
}
@media (max-width: 768px) {
  .row-header{
    padding-top: 20px;
    margin-top: 10px;
  }
  .header .v-card__title {
    font-size: 1.4em;
    line-height: 1em;
    word-break: break-word;
    display: flex;
  }
  .logo-link{
    padding-left: 0;
    float: left;
  }

  .strip-bar{
    height: 25px;
  }
}
</style>
